import Utils from "./utils";
import Blake2b from "@rabbit-company/blake2b";

Utils.initialize();

document.getElementById("login-form").addEventListener("submit", e => {
	e.preventDefault();
	login();
});

function login(){
	const username = document.getElementById("username").value.toLowerCase();
	const password = document.getElementById("password").value;

	let hash = Blake2b.hash("47rhzqe4r7fjsdkhfq8-" + username + "-" + password);

	let headers = new Headers();
	headers.set('Authorization', 'Basic ' + btoa(username + ':' + hash));
	headers.set('Content-Type', 'application/json');

	fetch('https://pieaapi.skypieamc.net/v1/account/token', {
		method: 'GET',
		headers: headers,
	}).then(result => {
		return result.json();
	}).then(response => {
		if(response.error !== 0){
			document.getElementById("error-message").innerText = response.info;
			return;
		}
		localStorage.setItem('username', username);
		localStorage.setItem('token', response.token);
		localStorage.setItem('logged', new Date().toISOString());
		window.location.href = "dashboard.html";
	}).catch(() => {
		document.getElementById("error-message").innerText = "Server is unreachable!";
	});
}