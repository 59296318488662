export default class Utils{

	static roles = {
		0: "Artist",
		1: "Builder",
		2: "SrBuilder",
		3: "Game Developer",
		4: "Intern",
		5: "Helper",
		6: "Mod",
		7: "SrMod",
		8: "Admin",
		9: "Code Master",
		10: "Game Master",
		11: "Manager",
		12: "Owner"
	};

	static initialize(){

		window.setInterval(function() {
			let logged = localStorage.getItem('logged');
			if(logged !== null){
				if((new Date(logged).getTime() + 3_600_000) < new Date().getTime()){
					localStorage.removeItem('token');
					localStorage.removeItem('logged');
					location.reload();
				}
			}
		}, 5000);
	}

	static logout(reload = true){
		localStorage.removeItem('token');
		localStorage.removeItem('logged');
		if(reload) location.reload();
	}

	static requireAuthentication(){
		let logged = localStorage.getItem('logged');
		if(logged === null) window.location.href = 'login.html';
	}

	static displayAdminNavbar(){
		return `<nav class="navbar">
		<div class="navbar-component-left">
			  <img src="assets/icons/logo.svg" alt="Logo" class="logo" />
			  <ul class="nav-links">
				<li>
				<a href="/">Users</a>
				</li>
				<li>
				<a href="/tasks">Roles</a>
				</li>
				<li>
					<a href="/tasks">Roles</a>
				</li>
			</ul>
		</div>
		<div class="navbar-component-right">
			<div>
				<img src="assets/icons/search.svg" alt="pfp here" class="icon"/>
			</div>
		</div>
	  </nav>`;
	}
}